<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">关键供应商审核</p>
      <p class="SmallP" style="text-indent: 2em">
        对企业的关键供应商进行审核策划、组织、实施、发现、记录和整改关闭。
      </p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        企业的供应商，是企业的外部不可分割的资源，供应商的品质管理和交付绩效直接影响本企业的体系能力，如何管好供应商，直接关系到企业的生存与发展。用体系管理的办法，对供应商进行审核监督，助其提升整体管理水平，尤其是质量管理水平，是当今企业供应链管理最有效的方法。景翔公司拥有一批服务于汽车供应链企业的专业顾问团队，多年的IATF16949体系与VDA6.3过程审核、VDA6.5产品审核和五大核心工具应用培训实操经验，对供应商的审核策划、组织、实施、发现、记录和整改关闭等，形成了一套景翔独有的体系方法及应用模板，同时可实现无纸化，系统平台连接手机终端的智能操作。
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/39.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/40.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/41.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/42.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP" style="text-indent: 2em">
        依据IATF16949体系和VDA6.3过程审核标准方法，应用景翔独有的一体化的体系方法及模板，实现无纸化、系统平台连接手机终端的智能高效和低成本，帮助企业充分有效地实施关键供应商的二方审核。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>